export const enum DonationOrganization {
    name = "굿윌스토어",
    fullName = "굿윌스토어 성남점",
    registrationNumber = "129-82-07810",
    address = "경기도 성남시 중원구 둔촌대로 484, 시콕스타워 215호",
}

export class CharanFactoryDeliveryInfo {
    static readonly addressName = "차란";
    static readonly phoneNumber = "031-510-4334";
    static readonly postalCode = "12048";
    static readonly address = "경기도 남양주시 오남읍 양지로 149-78, 대한일 3층 차란";
}

export const STYLE_METADATA_ID = {
    MINI_SKIRT: 61,
    MIDI_SKIRT: 62,
    LONG_SKIRT: 63,
    MINI_DRESS: 64,
    MIDI_DRESS: 65,
    LONG_DRESS: 66,
} as const;
type STYLE_METADATA_ID = typeof STYLE_METADATA_ID[keyof typeof STYLE_METADATA_ID];

export const DISPLAY_EXCLUDED_STYLE_METADATA_IDS = [
    STYLE_METADATA_ID.MINI_SKIRT,
    STYLE_METADATA_ID.LONG_SKIRT,
    STYLE_METADATA_ID.MINI_DRESS,
    STYLE_METADATA_ID.LONG_DRESS,
];

export const STANDARD_ZPL_LABEL_PRINT_COUNT = 1;

export const CJ_RESERVATION_EXCEL_DOWNLOAD_BUTTON_TEXT = "CJ 예약접수파일 다운로드";

export const TRACKING_NUMBER_REGISTER_BUTTON_TEXT = "발송 운송장번호 등록";

export const BAG_TYPE = {
    PREMIUM: "premium",
    STANDARD: "standard",
    ACCESSORY: "accessory",
} as const;
export type BAG_TYPE = typeof BAG_TYPE[keyof typeof BAG_TYPE];

export const BAG_TYPE_ABBREVIATION = {
    [BAG_TYPE.PREMIUM]: "P",
    [BAG_TYPE.STANDARD]: "S",
    [BAG_TYPE.ACCESSORY]: "A",
} as const;

export const ACCESSORY_MAIN_CATEGORY = {
    BAG: "bag",
    WALLET: "wallet",
    HAT: "hat",
    MUFFLER: "muffler",
};
export type ACCESSORY_MAIN_CATEGORY =
    typeof ACCESSORY_MAIN_CATEGORY[keyof typeof ACCESSORY_MAIN_CATEGORY];
export const isAccessoryMainCategory = (mainCategoryId: string) =>
    Object.values(ACCESSORY_MAIN_CATEGORY).includes(mainCategoryId);

export const CLOTHES_MAIN_CATEGORY = {
    OUTER: "outer",
    TOP: "top",
    BOTTOM: "bottom",
    DRESS: "onepiece",
    SET: "set",
};
export type CLOTHES_MAIN_CATEGORY = typeof CLOTHES_MAIN_CATEGORY[keyof typeof CLOTHES_MAIN_CATEGORY];
export const isClothesMainCategory = (mainCategoryId: string) => {
    return Object.values(CLOTHES_MAIN_CATEGORY).includes(mainCategoryId);
};

export const EMPTY_ACCESSORY_MEASURE_SIZE = {
    bag: { width: "", length: "", height: "", strapLength: "" },
    wallet: { width: "", length: "" },
    hat: { headDiameter: "", depth: "", brimLength: "" },
    muffler: { width: "", length: "" },
};

export const CORE_ROLE = {
    MASTER: "MASTER",
    FM: "FM",
};
export type CORE_ROLE = typeof CORE_ROLE[keyof typeof CORE_ROLE];

export const USER_INFO_COOKIE_KEY = "coreUser";
export const ACCESS_TOKEN_COOKIE_KEY = "accessToken";

export const BRAND_CATEGORY = {
    SHOPPINGMALL: "쇼핑몰",
    UNCLASSIFIED: "미분류",
};
export type BRAND_CATEGORY = typeof BRAND_CATEGORY[keyof typeof BRAND_CATEGORY];

export const SEARCH_OPTION_ID = {
    CLOSET_ID: "closetIDs",
    CHARAN_USER_ID: "charanUserIDs",
    CHARAN_USER_NAME: "charanUserRealNames",
    BAG_ID: "charanbagIDs",
};
export type SEARCH_OPTION_ID = typeof SEARCH_OPTION_ID[keyof typeof SEARCH_OPTION_ID];

export const SEARCH_OPTION_DISPLAY_NAME = {
    [SEARCH_OPTION_ID.CLOSET_ID]: "Provider Order ID",
    [SEARCH_OPTION_ID.CHARAN_USER_ID]: "회원 ID",
    [SEARCH_OPTION_ID.CHARAN_USER_NAME]: "회원 이름",
    [SEARCH_OPTION_ID.BAG_ID]: "Bag ID",
};

export const NOT_SELECTED_STATUS = 0;

export const META_DATA_API_KEY = {
    CHARAN_BAG_ORDER_STATUS: "charan-bag-order-status",
    CHARAN_BAG_STATUS: "charan-bag-status",
    COLLECTION_STATUS: "collection-status",
    DELIVERY_STATUS: "delivery-status",
    DONATION_STATUS: "donation-status",
    DONOR_ORG: "donor-org",
    ITEM_BRAND: "item-brand",
    ITEM_BRAND_CAT: "item-brand-cat",
    ITEM_CONDITION: "item-condition",
    ITEM_DP_SIZE: "item-dp-size",
    ITEM_DP_SIZE_CAT: "item-dp-size-cat",
    ITEM_MAIN_CAT: "item-main-cat",
    ITEM_MATERIAL: "item-material",
    ITEM_MEASURE_SIZE_CAT: "item-measure-size-cat",
    SEASONALITY: "seasonality",
    ITEM_STATUS: "item-status",
    ITEM_STYLE_CAT: "item-style-cat",
    ITEM_SUB_CAT: "item-sub-cat",
    ORDER_STATUS: "order-status",
    PAYMENT_TYPE: "payment-type",
    RETURNING_STATUS: "returning-status",
    SETTLEMENT_STATUS: "settlement-status",
    TYPE_BANK: "ty-bank",
    TYPE_CREDIT_CHANGED: "ty-credit-changed",
    KG_SELLING_STATUS: "kg-selling-status",
};
export type META_DATA_API_KEY = typeof META_DATA_API_KEY[keyof typeof META_DATA_API_KEY];
