import { ACCESS_TOKEN_COOKIE_KEY, USER_INFO_COOKIE_KEY } from "../constant";
import { setCookie } from "../store/Cookie";
import { httpPost } from "./apiService";
import { requestGetAllMetaData, storeAllMetaDataToLocalStorage } from "./metaService";

export interface SignInRequest {
    id: string;
    password: string;
}

export interface SignInResponse {
    accessToken: string;
    refreshToken: string;
    user: {
        id: string;
        englishName: string;
        coreRole: string;
    };
}

const requestSignIn = async (data: SignInRequest): Promise<SignInResponse> => {
    const response = await httpPost("/api/iam/sign-in/login", data);
    return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        user: {
            id: response.data.id,
            englishName: response.data.englishName,
            coreRole: response.data.role,
        },
    };
};

export interface OAuthSignInRequest {
    provider: string;
    oauthToken: string;
}

const requestOAuthSignIn = async (data: OAuthSignInRequest): Promise<SignInResponse> => {
    const response = await httpPost("/api/iam/sign-in", data);
    return response;
};

const handleSignInSuccess = async (response: SignInResponse) => {
    setCookie(USER_INFO_COOKIE_KEY, JSON.stringify(response.user), { path: "/" });
    setCookie(ACCESS_TOKEN_COOKIE_KEY, response.accessToken, { path: "/" });
    const metaData = await requestGetAllMetaData();
    if (metaData) {
        storeAllMetaDataToLocalStorage(metaData);
    }
};

export interface ChangePasswordRequest {
    id: string;
    oldPassword: string;
    newPassword: string;
}

const requestChangePassword = async (data: ChangePasswordRequest): Promise<null> => {
    const response = await httpPost("/api/iam/update-password", data);
    return response;
};

export { requestSignIn, requestOAuthSignIn, handleSignInSuccess, requestChangePassword };
