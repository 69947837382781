import axios from "axios";
import { httpGet } from "./apiService";
import { META_DATA_API_KEY } from "../constant";

export type measureSizeType = "total" | "sleeve" | "shoulder" | "breast" | "waist" | "hip";

const META_DATA_LOCAL_STORAGE_KEY = "meta";

export interface MeasureSizeCat {
    coreDPName: string;
    providerDPName: string;
    shopperDPName: string;
    coreDescription: string;
    providerDescription: string;
    shopperDescription: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
    id: measureSizeType;
}
export interface MetaDataState {
    charanBagOrderStatus: { [key: string]: any };
    charanBagStatus: { [key: string]: any };
    collectionStatus: { [key: string]: any };
    commission: { [key: string]: any };
    deliveryStatus: { [key: string]: any };
    donationStatus: { [key: string]: any };
    donorOrg: { [key: string]: any };
    itemBrand: { [key: string]: any };
    itemBrandCat: { [key: string]: any };
    itemCondition: { [key: string]: any };
    itemDpSize: { [key: string]: any };
    itemDpSizeCat: { [key: string]: any };
    itemMainCat: { [key: string]: any };
    itemMaterial: { [key: string]: any };
    itemMeasureSizeCat: MeasureSizeCat[];
    itemSeasonality: { [key: string]: any };
    itemStatus: { [key: string]: any };
    itemStyleCat: { [key: string]: any };
    itemSubCat: { [key: string]: any };
    itemUdBrand: { [key: string]: any };
    itemUdDpSize: { [key: string]: any };
    itemUdStyleCat: { [key: string]: any };
    orderStatus: { [key: string]: any };
    paymentStatus: { [key: string]: any };
    paymentType: { [key: string]: any };
    returningStatus: { [key: string]: any };
    settlementStatus: { [key: string]: any };
    typeBank: { [key: string]: any };
    typeCreditChanged: { [key: string]: any };
    kgSellingStatus: { [key: string]: any };
}

const DEFAULT_META_DATA: MetaDataState = {
    charanBagOrderStatus: [],
    charanBagStatus: [],
    collectionStatus: [],
    commission: [],
    deliveryStatus: [],
    donationStatus: [],
    donorOrg: [],
    itemBrand: [],
    itemBrandCat: [],
    itemCondition: [],
    itemDpSize: [],
    itemDpSizeCat: [],
    itemMainCat: [],
    itemMaterial: [],
    itemMeasureSizeCat: [],
    itemSeasonality: [],
    itemStatus: [],
    itemStyleCat: [],
    itemSubCat: [],
    itemUdBrand: [],
    itemUdDpSize: [],
    itemUdStyleCat: [],
    orderStatus: [],
    paymentStatus: [],
    paymentType: [],
    returningStatus: [],
    settlementStatus: [],
    typeBank: [],
    typeCreditChanged: [],
    kgSellingStatus: [],
};

const requestGetMetaData = async (metaName: META_DATA_API_KEY) => {
    return await httpGet("/api/meta/" + metaName);
};

const getAllMetaDataFromLocalStorage = () => {
    const metaData = localStorage.getItem(META_DATA_LOCAL_STORAGE_KEY);
    if (!metaData) {
        return null;
    }
    return JSON.parse(metaData);
};

const storeAllMetaDataToLocalStorage = (allMetaData: MetaDataState) => {
    localStorage.setItem(META_DATA_LOCAL_STORAGE_KEY, JSON.stringify(allMetaData));
};

// TODO(Ginny): need to GET metadata logic refactoring
const requestGetAllMetaData = async () => {
    try {
        const responses = await axios.all([
            requestGetMetaData(META_DATA_API_KEY.CHARAN_BAG_ORDER_STATUS),
            requestGetMetaData(META_DATA_API_KEY.CHARAN_BAG_STATUS),
            requestGetMetaData(META_DATA_API_KEY.COLLECTION_STATUS),
            requestGetMetaData(META_DATA_API_KEY.DELIVERY_STATUS),
            requestGetMetaData(META_DATA_API_KEY.DONATION_STATUS),
            requestGetMetaData(META_DATA_API_KEY.DONOR_ORG),
            requestGetMetaData(META_DATA_API_KEY.ITEM_BRAND),
            requestGetMetaData(META_DATA_API_KEY.ITEM_BRAND_CAT),
            requestGetMetaData(META_DATA_API_KEY.ITEM_CONDITION),
            requestGetMetaData(META_DATA_API_KEY.ITEM_DP_SIZE),
            requestGetMetaData(META_DATA_API_KEY.ITEM_DP_SIZE_CAT),
            requestGetMetaData(META_DATA_API_KEY.ITEM_MAIN_CAT),
            requestGetMetaData(META_DATA_API_KEY.ITEM_MATERIAL),
            requestGetMetaData(META_DATA_API_KEY.ITEM_MEASURE_SIZE_CAT),
            requestGetMetaData(META_DATA_API_KEY.SEASONALITY),
            requestGetMetaData(META_DATA_API_KEY.ITEM_STATUS),
            requestGetMetaData(META_DATA_API_KEY.ITEM_STYLE_CAT),
            requestGetMetaData(META_DATA_API_KEY.ITEM_SUB_CAT),
            requestGetMetaData(META_DATA_API_KEY.ORDER_STATUS),
            requestGetMetaData(META_DATA_API_KEY.PAYMENT_TYPE),
            requestGetMetaData(META_DATA_API_KEY.RETURNING_STATUS),
            requestGetMetaData(META_DATA_API_KEY.SETTLEMENT_STATUS),
            requestGetMetaData(META_DATA_API_KEY.TYPE_BANK),
            requestGetMetaData(META_DATA_API_KEY.TYPE_CREDIT_CHANGED),
            requestGetMetaData(META_DATA_API_KEY.KG_SELLING_STATUS),
        ]);
        DEFAULT_META_DATA.charanBagOrderStatus = responses[0].charanBagOrderStatus;
        DEFAULT_META_DATA.charanBagStatus = responses[1].charanBagStatus;
        DEFAULT_META_DATA.collectionStatus = responses[2].collectionStatus;
        DEFAULT_META_DATA.deliveryStatus = responses[3].deliveryStatus;
        DEFAULT_META_DATA.donationStatus = responses[4].donationStatus;
        DEFAULT_META_DATA.donorOrg = responses[5].donorOrgs;
        DEFAULT_META_DATA.itemBrand = responses[6].itemBrands;
        DEFAULT_META_DATA.itemBrandCat = responses[7].itemBrandCats;
        DEFAULT_META_DATA.itemCondition = responses[8].itemConditions.sort(
            (a: any, b: any) => a.id - b.id
        );
        DEFAULT_META_DATA.itemDpSize = responses[9].itemDPSizes.sort((a: any, b: any) => a.id - b.id);
        DEFAULT_META_DATA.itemDpSizeCat = responses[10].itemDPSizeCats;
        DEFAULT_META_DATA.itemMainCat = responses[11].itemMainCats;
        DEFAULT_META_DATA.itemMaterial = responses[12].itemMaterials;
        DEFAULT_META_DATA.itemMeasureSizeCat = responses[13].itemMeasureSizeCats;
        DEFAULT_META_DATA.itemSeasonality = responses[14].seasonalities;
        DEFAULT_META_DATA.itemStatus = responses[15].itemStatus;
        DEFAULT_META_DATA.itemStyleCat = responses[16].itemStyleCats;
        DEFAULT_META_DATA.itemSubCat = responses[17].itemSubCats;
        DEFAULT_META_DATA.orderStatus = responses[18].orderStatus;
        DEFAULT_META_DATA.paymentType = responses[19].paymentType;
        DEFAULT_META_DATA.returningStatus = responses[20].returningStatus;
        DEFAULT_META_DATA.settlementStatus = responses[21].settlementStatus;
        DEFAULT_META_DATA.typeBank = responses[22].tyBanks;
        DEFAULT_META_DATA.typeCreditChanged = responses[23].tyCreditChangeds;
        DEFAULT_META_DATA.kgSellingStatus = responses[24].data.kgSellingStatuses;
        return DEFAULT_META_DATA;
    } catch (error) {
        console.warn(error);
        alert("Meta data를 가져오는 도중 오류가 발생했습니다.");
        return null;
    }
};

export {
    requestGetMetaData,
    requestGetAllMetaData,
    getAllMetaDataFromLocalStorage,
    storeAllMetaDataToLocalStorage,
};
